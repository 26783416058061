import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

let swiper = null;

function initializeSwiper() {
  swiper = new Swiper('.trust-swiper-container', {
    modules: [Navigation],
    direction: 'horizontal',
    loop: true,
    navigation: {
      nextEl: '.trust-swiper-button-next',
      prevEl: '.trust-swiper-button-prev',
    },
  });
}

function destroySwiper() {
  if (swiper !== null) {
    swiper.destroy(true, true);
    swiper = null;
  }
}

function toggleSwiperBasedOnWidth(maxWidth) {
  const screenWidth = window.innerWidth;
  if (screenWidth <= maxWidth) {
    if (swiper === null) {
      initializeSwiper();
    }
  } else {
    if (swiper !== null) {
      destroySwiper();
    }
  }
}

window.addEventListener('resize', function () {
  toggleSwiperBasedOnWidth(833);
});
// тут выходит костыль какой-то, потому что  ширина почему считаеться больше чем есть на самом деле

toggleSwiperBasedOnWidth(833);
