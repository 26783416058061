import imgFirstSmall1x from '../../img/mediateka/image11-small-1x.webp';
import imgFirstSmall2x from '../../img/mediateka/image11-small-2x.webp';
import imgFirstLarge1x from '../../img/mediateka/image11-large-1x.webp';
import imgFirstLarge2x from '../../img/mediateka/image11-large-2x.webp';

import imgSecondSmall1x from '../../img/mediateka/image12-small-1x.webp';
import imgSecondSmall2x from '../../img/mediateka/image12-small-2x.webp';
import imgSecondLarge1x from '../../img/mediateka/image12-large-1x.webp';
import imgSecondLarge2x from '../../img/mediateka/image12-large-2x.webp';

import imgThirdSmall1x from '../../img/mediateka/image13-small-1x.webp';
import imgThirdSmall2x from '../../img/mediateka/image13-small-2x.webp';
import imgThirdLarge1x from '../../img/mediateka/image13-large-1x.webp';
import imgThirdLarge2x from '../../img/mediateka/image13-large-2x.webp';

import imgFourSmall1x from '../../img/mediateka/image14-small-1x.webp';
import imgFourSmall2x from '../../img/mediateka/image14-small-2x.webp';
import imgFourLarge1x from '../../img/mediateka/image14-large-1x.webp';
import imgFourLarge2x from '../../img/mediateka/image14-large-2x.webp';

import imgFiveSmall1x from '../../img/mediateka/image15-small-1x.webp';
import imgFiveSmall2x from '../../img/mediateka/image15-small-2x.webp';
import imgFiveLarge1x from '../../img/mediateka/image15-large-1x.webp';
import imgFiveLarge2x from '../../img/mediateka/image15-large-2x.webp';

import imgSixSmall1x from '../../img/mediateka/image16-small-1x.webp';
import imgSixSmall2x from '../../img/mediateka/image16-small-2x.webp';
import imgSixLarge1x from '../../img/mediateka/image16-large-1x.webp';
import imgSixLarge2x from '../../img/mediateka/image16-large-2x.webp';

const isRetina = window.matchMedia('(min-resolution: 2dppx)').matches;

export const photos = [
  {
    id: 1,
    original: `${isRetina ? imgFirstLarge2x : imgFirstLarge1x}`,
    preview: `${isRetina ? imgFirstSmall2x : imgFirstSmall1x}`,

    description:
      'Наша чудова команда працівників центру навколо засновниці дивляться в книжку яку та тримає в руках.',
  },
  {
    id: 2,
    original: `${isRetina ? imgSecondLarge2x : imgSecondLarge1x}`,
    preview: `${isRetina ? imgSecondSmall2x : imgSecondSmall1x}`,
    description: 'Наші чудові спеціалісти',
  },
  {
    id: 3,
    original: `${isRetina ? imgThirdLarge2x : imgThirdLarge1x}`,
    preview: `${isRetina ? imgThirdSmall2x : imgThirdSmall1x}`,
    description:
      'Команда працівників нашого центру одягнені в білі футболки із стрічками кольорів українського прапору.',
  },
  {
    id: 4,
    original: `${isRetina ? imgFourLarge2x : imgFourLarge1x}`,
    preview: `${isRetina ? imgFourSmall2x : imgFourSmall1x}`,
    description: 'Наші чудові спеціалісти',
  },
  {
    id: 5,
    original: `${isRetina ? imgFiveLarge2x : imgFiveLarge1x}`,
    preview: `${isRetina ? imgFiveSmall2x : imgFiveSmall1x}`,
    description: 'Наші чудові спеціалісти',
  },
  {
    id: 6,
    original: `${isRetina ? imgSixLarge2x : imgSixLarge1x}`,
    preview: `${isRetina ? imgSixSmall2x : imgSixSmall1x}`,
    description: 'Наші чудові спеціалісти',
  },
];
