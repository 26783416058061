import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

import { openAppointmentModal } from './modal-appointment';

document
  .getElementById('team-appointment-button')
  .addEventListener('click', openAppointmentModal);

let swiper = null;

function initializeSwiper() {
  swiper = new Swiper('.team-swiper-container', {
    modules: [Navigation],
    direction: 'horizontal',
    loop: true,
    navigation: {
      nextEl: '.team-swiper-button-next',
      prevEl: '.team-swiper-button-prev',
    },
    slidesPerView: 1,
    centeredSlidesBounds: true,
    breakpoints: {
      835: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
    },
  });
}

function destroySwiper() {
  if (swiper !== null) {
    swiper.destroy(true, true);
    swiper = null;
  }
}

function toggleSwiperBasedOnWidth(maxWidth) {
  const screenWidth = window.innerWidth;
  if (screenWidth <= maxWidth) {
    if (swiper === null) {
      initializeSwiper();
    }
  } else {
    if (swiper !== null) {
      destroySwiper();
    }
  }
}

window.addEventListener('resize', function () {
  toggleSwiperBasedOnWidth(1439);
});
// тут выходит костыль какой-то, потому что  ширина почему считаеться больше чем есть на самом деле

toggleSwiperBasedOnWidth(1439);
