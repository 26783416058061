import SimpleLightbox from 'simplelightbox';
import 'simplelightbox/dist/simple-lightbox.min.css';

import { photos } from './mediateca/gallery';

const gallery = document.getElementById('photo-gallery');

const imagesMarkup = photos
  .map(({ preview, original, description }) =>
    window.innerWidth > 833
      ? `<li class="gallery__item">
   <a class="gallery__link" href="${original}">
      <img loading="lazy" class="gallery__image" 
      src="${preview}" alt="${description}" />
   </a>
</li>`
      : `<li class="gallery__item">
      <img loading="lazy" class="gallery__image" 
      src="${preview}" alt="${description}" />
  
</li>`
  )
  .join('');

gallery.insertAdjacentHTML('beforeend', imagesMarkup);

if (window.innerWidth > 833) {
  new SimpleLightbox('.gallery__link', {
    captionDelay: 250,
    captionsData: '',
    scrollZoom: false,
  });
}
